<template>
  <Layout>
    <v-row style="background:#fafafa;">
      <v-col cols="12" class="pa-0">
        
        <v-row class="py-1 px-5">
          <v-col cols="12" md="12">
            <v-row>
              <v-col cols="12" class="px-5" PANNELS>
                <v-card NotificationPanel :loading="loading">
                  <v-card-text class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-row class="align-center">
                          <v-col cols="2" class="text-center" TITLE>
                            <v-icon size="40" color="primary">people</v-icon>
                          </v-col>
                          <v-col cols="10" TITLE>
                            <div :class="`${arabic?'arFont':'enFont'} black--text`">
                              {{ i18n.all_users }}
                            </div>
                            <div :class="`${arabic?'arFont':'enFont'} grey--text`">
                              {{ i18n.manage_platform_users }}
                            </div>
                          </v-col>
                          <v-col cols="12" md="4" ADD-USERS v-if="(user.role == 'master' || user.multi)">
                            <div>
                              <v-select
                                :items="accounts"
                                v-model="selectedInstitute"
                                :class="`${arabic?'arFont':'enFont'}`"
                                :label="i18n.institution"
                                outlined
                                item-text="name"
                                item-value="id"
                                dense
                                clearable
                                @change="filterUsers()"
                                hide-details
                              >
                                <template v-slot:selection="{ attr, on, item, selected }">
                                  <span
                                    v-bind="attr"
                                    :input-value="selected"
                                    v-on="on"
                                    :class="`${arabic?'arFont':'enFont'}`"
                                  >
                                    <v-chip x-small>{{item.name}}</v-chip>
                                  </span>
                                </template>
                                <template v-slot:item="{ item }">
                                  <span
                                    :class="`${arabic?'arFont':'enFont'}`"
                                  >
                                    {{item.name}}
                                  </span>
                                </template>
                              </v-select>
                            </div>
                          </v-col>
                          <v-col cols="12" md="4" ADD-USERS v-if="(user.role == 'master' || user.multi)">
                            <div>
                              <v-text-field
                                v-model="search_term"
                                :class="`${arabic?'arFont':'enFont'}`"
                                :label="i18n.search"
                                outlined
                                dense
                                clearable
                                @change="filterUsers()"
                                hide-details
                              >
                              </v-text-field>
                            </div>
                          </v-col>
                          <v-col cols="12" md="4" ADD-USERS v-if="(user.role == 'master' || user.multi)">
                          </v-col>
                          
                          <v-col cols="12" ADD-USERS>
                            <v-btn color="success" @click="newDialog = true" :class="`${arabic?'arFont':'enFont'}`" :disabled="checkDisabled">
                              {{ i18n.add_user }}
                            </v-btn>
                            <span :class="`${arabic?'arFont':'enFont'} px-2`" v-if="accounts.length && user.role != 'master'">
                              {{ i18n.left_tokens }} {{resource.tokens}} {{ i18n.user_users }}
                            </span>
                            <v-layout row justify-center>
                              <v-dialog v-model="newDialog" persistent max-width="600">
                                <v-card>
                                  <v-card-title :class="`${arabic?'arFont':'enFont'}`" style="font-size:22px;font-weight:bold;">
                                    {{ i18n.add_user }}
                                    
                                  </v-card-title>
                                  <v-card-text>
                                    <v-form>
                                      <v-autocomplete 
                                        :label="i18n.institution" 
                                        type="text" 
                                        dense
                                        item-text="name"
                                        item-value="id"
                                        :items="accounts"
                                        v-if="user.role == 'master' || user.multi"
                                        outlined
                                        @keypress.enter="addUser()"
                                        :class="`${arabic?'arFont':'enFont'}`"
                                        v-model.trim="newUser.account_id">
                                        <template v-slot:selection="{ attr, on, item, selected }">
                                          <span
                                            v-bind="attr"
                                            :input-value="selected"
                                            v-on="on"
                                            :class="`${arabic?'arFont':'enFont'}`"
                                          >
                                            <v-chip x-small>{{item.name}}</v-chip>
                                          </span>
                                        </template>
                                        <template v-slot:item="{ item }">
                                          <span
                                            :class="`${arabic?'arFont':'enFont'}`"
                                          >
                                            {{item.name}}
                                          </span>
                                        </template>
                                      </v-autocomplete>
                                      <v-text-field 
                                        append-icon="person" 
                                        name="name" 
                                        :label="i18n.empoyee_name" 
                                        type="text" 
                                        dense
                                        outlined
                                        @keypress.enter="addUser()"
                                        :class="`${arabic?'arFont':'enFont'}`"
                                        v-model.trim="newUser.name">
                                      </v-text-field>
                                      <v-text-field 
                                        append-icon="email" 
                                        name="email" 
                                        :label="i18n.email" 
                                        type="text" 
                                        dense
                                        outlined
                                        @keypress.enter="addUser()"
                                        :class="`${arabic?'arFont':'enFont'}`"
                                        v-model.trim="newUser.email">
                                      </v-text-field>
                                    </v-form>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" text @click.native="newDialog = false" :class="`${arabic?'arFont':'enFont'}`">
                                      {{ i18n.cancel }}
                                    </v-btn>
                                    <v-btn color="primary" text @click.native="addUser()" :class="`${arabic?'arFont':'enFont'}`">
                                      {{ i18n.add }}
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </v-layout>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <v-row class="align-center">
                          <v-col cols="12" :class="`${arabic?'arFont':'enFont'}`">
                            <Paging 
                              :resourceSettings="resourceSettings" 
                              :resource="resource"
                              @loadResource="loadResource"
                              @pageByNumber="pageByNumber"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-dialog
                              v-model="dialog"
                              scrollable 
                              persistent :overlay="false"
                              max-width="800px"
                              transition="dialog-transition"
                            >
                              <v-card>
                                <v-card-actions>
                                  <v-btn color="error" :class="`${arabic?'arFont':'enFont'}`" text @click="dialog = false">
                                    {{i18n.close}}
                                  </v-btn>
                                </v-card-actions>
                                <iframe v-if="dialog" id="pdfreport" :src="pdfreport" height="800px" frameborder="0"></iframe>
                              </v-card>
                            </v-dialog>
                          </v-col>
                          <v-col cols="12" :class="`${arabic?'arFont':'enFont'}`">
                            <v-expansion-panels focusable>
                              <v-expansion-panel v-for="(r, i) in resource.data" :key="'resource-'+i"  style="cursor:pointer;">
                                <v-expansion-panel-header>
                                  <v-row class="pa-0 align-center">
                                    <v-col cols="12" md="3" :class="`${arabic?'arFont':'enFont'} pa-0 py-1 font-weight-bold`">
                                      <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                          <span v-bind="attrs" v-on="on">{{r.name}}</span>
                                        </template>
                                        <div class="text-center">
                                          <div :class="`${arabic?'arFont':'enFont'}`">{{r.email}}</div>
                                        </div>
                                      </v-tooltip>
                                    </v-col>
                                    <v-col cols="12" md="3" :class="`${arabic?'arFont':'enFont'} pa-0 py-1`">
                                      <div style="font-size:11px;" v-if="r.account">{{r.account.name}}</div>
                                    </v-col>
                                    <v-col cols="12" md="2" :class="`${arabic?'arFont':'enFont'} pa-0 py-1 adminSwitch text-left`">
                                      <v-switch @change="updateResource(r)" 
                                        hide-details inset

                                        :class="`${arabic?'arFont':'enFont'} mt-0`" 
                                        :name="i18n.access"
                                        :label="i18n.access" v-model="r.has_access"></v-switch>
                                    </v-col>
                                    <v-col cols="11" md="3" :class="`${arabic?'arFont':'enFont'} pa-0 py-1 adminSwitch text-left`">
                                      <v-select
                                        :items="roles"
                                        v-model="r.role"
                                        :label="i18n.userRole"
                                        :name="i18n.userRole"
                                        outlined
                                        :disabled="user.role != 'master'"
                                        :readonly="user.role != 'master'"
                                        hide-details
                                        dense
                                        @change="updateResource(r)"
                                        item-value="slug"
                                        :class="`${arabic?'arFont':'enFont'}`"
                                      >
                                        <template v-slot:selection="{ attr, on, item, selected }">
                                          <span
                                            v-bind="attr"
                                            :input-value="selected"
                                            v-on="on"
                                            :class="`${arabic?'arFont':'enFont'}`"
                                          >
                                            {{item.text}}
                                          </span>
                                        </template>
                                        <template v-slot:item="{ item }">
                                          <span
                                            :class="`${arabic?'arFont':'enFont'}`"
                                          >
                                            {{item.text}}
                                          </span>
                                        </template>
                                      </v-select>
                                    </v-col>
                                    <v-col cols="1" md="1" :class="`${arabic?'arFont':'enFont'} pa-0 py-1 adminSwitch text-center`">
                                      <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                          <span v-bind="attrs" v-on="on">
                                            <v-avatar
                                              size="10"
                                              :color="r.online?'success':'error'"
                                            >
                                            </v-avatar>
                                          </span>
                                        </template>
                                        <div :class="`${arabic?'arFont':'enFont'}`">{{getFormat(r.updated_at)}}</div>
                                      </v-tooltip>
                                    </v-col>
                                  </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content v-if="false">
                                  <v-divider class="pb-3"></v-divider>
                                  <v-row class="pa-0 align-center">
                                    <v-col cols="12" md="6" :class="`${arabic?'arFont':'enFont'} pa-1 adminSwitch text-left`">
                                      
                                    </v-col>
                                    <v-col cols="12" md="12" :class="`${arabic?'arFont':'enFont'} pa-1 adminSwitch text-left`">
                                      <v-btn color="success" block text @click="updateResource(r)">
                                        <v-icon left>save</v-icon>
                                        <span :class="`${arabic?'arFont':'enFont'}`">
                                          {{ i18n.update }}
                                        </span>
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                            
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </Layout>
</template>

<script>
import Layout from '@/components/Layout'
import Search from '@/components/Search'
import Paging from '@/components/Paging'
import Notifications from '@/components/Notifications'
import { format, render, cancel, register } from 'timeago.js';
import axios from 'axios';
const headers = {'Accept': 'application/json'};
const fireapi = 'https://us-central1-asklytic.cloudfunctions.net/main';

export default {
  components: { Paging, Notifications, Layout },
  data() {
    return {
      loading:false,
      dialog:false,
      pdfreport:null,
      vresource:[],
      resource:[],
      search:null,
      rules: {
        required: value => !!value || this.i18n['required'],
        email: value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || this.i18n['valid_email']
      },
      resourceKeys:[
        
      ],
      resourceSettings:{
        resourceName:null,
        search:false,
        create:false,
        pagination:true,
      },
      newResource:{},
      editing:false,
      deleting:false,
      newDialog:false,
      newUser:{},
      accounts:[],
      selectedInstitute:false,
      search_term:null,
    }
  },
  methods: {
    getInstituteHash(id){
      return this.accounts.filter(x=>x.id==id)[0].hash
    },
    getFormat(d){
      return format(d)
    },
    filterObject(item, queryText, itemText) {
      let v = Object.values(item).filter(x=>typeof x == 'string')
      let a = false
      v.forEach(x=>{
        if(x.toLowerCase().includes(queryText.toLowerCase())) a = true
      })
      if(itemText && typeof itemText == 'string' && itemText.toLowerCase().includes(queryText.toLowerCase())) a = true
      return a
    },
    clipboardSuccessHandler (){
      this.$emit('snackbarNotification', `Copied`, 'success')
    },
    clipboardErrorHandler () {
      this.$emit('snackbarNotification', `Failed`, 'error')
    },
    async pageByNumber(n){
      return new Promise(async (res, rej)=>{
        this.loading = true
        if(!this.search){
          this.resource = await this.$store.dispatch('getResource', {resource:this.$route.path, page:`?page=${n}` + this.buildFilter()})
          this.accounts = this.resource.accounts
        } else {
          this.resource = await this.$store.dispatch('searchResource', {resource:this.$route.path + `/search?page=${n}`, value:this.search})
        }
        this.loading = false
      })
    },
    goTo(path) {
      this.$router.push(path).catch(x=>{})
    },
    buildFilter(){
      let filter = ``
      if(this.selectedInstitute) {
        filter += `&filter[account]=${this.selectedInstitute}`
      }
      if(this.search_term) {
        filter += `&filter[search]=${this.search_term}`
      }
      return filter
    },
    async filterUsers(){
      await this.loadResource()
    },
    firstLoad(){
      if(this.user) {
      }
    },
    async loadResource() {
      return new Promise(async (res, rej)=>{
        this.loading = true
        if(!this.search){
          this.resource = await this.$store.dispatch('getResource', {resource:this.$route.path + '?' + this.buildFilter()})
          console.log(this.resource)
          this.accounts = this.resource.accounts
        } else {
          this.resource = await this.$store.dispatch('searchResource', {resource:this.$route.path + '/search', value:this.search})
        }
        this.loading = false
        res()
      })
    },
    async updateResource(r){
      return new Promise(async (res, rej)=>{
        this.loading = true
        // console.log(r)
        await this.$store.dispatch('putResource', {resource:this.$route.path + `/${r.id}`, value:r})
        this.loadResource()
        this.loading = false
      })
    },
    showPannel(v){
      this[v] = true
      let all = this.subitems.map(x=>x.value)
      all.filter(x=>x!=v).forEach(x=>this[x] = false)
    },
    setResourceName(){
      this.resourceSettings.resourceName = this.i18n[this.$route.path.replace('/', '')]
    },
    
    async addUser() {
      return new Promise(async (res, rej)=>{
        if(!this.newUser.name) {
          this.$emit('snackbarNotification', `${this.i18n[name_required]}`, 'error')
          return false
        }
        if(!this.newUser.email) {
          this.$emit('snackbarNotification', `${this.i18n[email_required]}`, 'error')
          return false
        }
        this.loading = true
        let r = await this.$store.dispatch('postResource', {resource:'/users', value:{...this.newUser}})
        if(!r.error) {
          this.$emit('snackbarNotification', `${this.i18n.accountCreated}`, 'success')
        } 
        // this.newDialog = false
        this.loading = false
      })
    }
  },
  computed:{
    checkDisabled(){
      if(this.user.role == 'master') {
        return false
      } else {
        return this.resource.tokens > 0 ? false : true
      }
    },
    api(){
      return this.$store.state.settings.apiBase
    },
    users(){
      let sa = this.$store.state.user && this.$store.state.user.role == 'superadmin'
      // console.log(sa)
      return this.$store.state.users.filter(x=>{
        if(!sa) {
          return x.superior == this.$store.state.user.level
        } else return x
      })
    },
    site(){
      return this.$store.state.site
    },
    items() {
      return this.$store.state.menu
    },
    superAdmin(){
      return this.$store.state.superAdmin
    },
    isLogin() {
      return this.$store.state.centralLogin
    },
    language(){
      return this.$store.state.language
    },
    globalLoading(){
      return this.$store.state.globalLoading
    },
    user(){
      return this.$store.state.user
    },
    i18n(){
      return this.$store.state.i18n[this.$store.state.language]
    },
    arabic(){
      if(this.$store.state.language == 'ar') return true
    },
    routeTitle(){
      let main = this.$store.state.menu.filter(x=>x.path == this.$route.path)
      let sub = [].concat(...this.$store.state.menu.map(x=>x.subs)).filter(x=>x).filter(x=>x.path == this.$route.path)
      if(main.length) {
        return this.$store.state.i18n[this.$store.state.language][main[0].text]
      } else if(sub.length) {
        return this.$store.state.i18n[this.$store.state.language][sub[0].text]
      } else {
        return ''
      }
    },
    roles(){
      return this.$store.state.userRoles
    },
    notifications(){
      return this.$store.state.notifications.sort((a, b)=> b.timestamp - a.timestamp)
    },
  },
  async created(){
    this.setResourceName()
    await this.loadResource()
  },
}
</script>

<style>

</style>